<template>
    <div>
        <div class="flex justify-between p-3 mb-3 border-b border-gray-200">
            <div class="flex">
                <!-- //SearchBar @change="searchInputChange" /> -->
            </div>
        </div>
        <StatusSelectInvoices ref="statusSelect" v-if="workMode === 'advanced'" @searchParametersChanged="searchParametersChanged" />
    </div>
</template>
<script>
import queryString from "query-string";
// import moment from "moment";

export default {
    data() {
        return {
            workMode: "advanced",
            //currency: "sek",
            //invoicesType: null,
            dateRange: [],
            preSelectedClient: {},
        };
    },

    components: {
        // SearchBar: () => import("./SearchBar.vue"),
        StatusSelectInvoices: () => import("./StatusSelectInvoices.vue"),
    },

    created() {
        this.checkPreselectedClient();
    },

    methods: {
        checkPreselectedClient() {
            const { clientId } = queryString.parse(window.location.search, { sort: false });
            this.preSelectedClient = {
                value: clientId,
            };
        },

        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },

        searchInputChange() {
            this.$emit("searchInputChange");
            this.$refs.statusSelect.getAll();
        },

        searchParametersChanged() {
            this.$emit("searchParametersChanged");
            setTimeout(() => {
                this.$refs.statusSelect.getAll();
            }, 100);
        },
    },
};
</script>
